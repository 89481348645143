import Vue from 'vue';
import VueBootstrap from 'bootstrap-vue';
import VueSuperagent from 'vue-superagent';
import VueHighlightJS from 'vue-highlightjs';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'highlight.js/scss/googlecode.scss';
import './css/coloradmin/base.scss';
import './css/custom/base.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import mixins from './mixins';
import filters from './filters';

Vue.use(VueBootstrap);
Vue.use(VueSuperagent, {
  baseUrl: '/api',
});
Vue.use(VueHighlightJS);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mixins,
  filters,
  render: (h) => h(App),
}).$mount('#app');
