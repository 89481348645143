<template>
  <div class="position-relative h-100">
    <loading-overlay v-if="checkingLogin" />
    <navigation v-show="!checkingLogin && !HideNavigation" />
    <div v-show="!checkingLogin">
      <router-view />
    </div>
  </div>
</template>

<script>
const Navigation = () => import('@/components/navigation/Navigation.vue');
const LoadingOverlay = () => import('@/components/LoadingOverlay.vue');

export default {
  name: 'App',
  components: {
    Navigation,
    LoadingOverlay,
  },
  computed: {
    HideNavigation() {
      return this.$route.name === 'SignIn' || this.$route.name === 'SignOut';
    },
  },
  data() {
    return {
      account: this.$store.state.account,
      checkingLogin: false,
    };
  },
  methods: {
    checkLogin() {
      if (this.checkingLogin) return;
      this.checkingLogin = true;
      this.$http
        .get('/account/check_login')
        .then((res) => {
          if ('account' in res.body) {
            this.account = Object.assign(this.account, res.body.account);
            this.$store.dispatch('fetchMandantList');
            if (this.HideNavigation) {
              this.$router.push('/feeds');
            }
          }
        })
        .catch(() => {
          this.$router.push('/signin', () => {}, () => {});
        })
        .finally(() => {
          this.checkingLogin = false;
        });
    },
  },
  created() {
    this.checkLogin();
  },
};
</script>

<style lang="scss" scoped>
</style>
