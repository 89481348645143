import Vue from 'vue';
import Vuex from 'vuex';
import VueSuperagent from 'vue-superagent';

Vue.use(Vuex);
Vue.use(VueSuperagent, {
  baseUrl: '/api',
});

const defaultAccount = {
  admin: 0,
  display_name: '',
  id: 0,
  login: '',
};

export default new Vuex.Store({
  state: {
    account: defaultAccount,
    app: {
      mandantCode: '',
      mandantList: [],
    },
  },
  mutations: {
    signIn(state, account) {
      state.account = account;
    },
    signOut(state) {
      state.account = defaultAccount;
      state.app.mandantCode = '';
    },
    loadMandantCode(state) {
      if (state.app.mandantList.length === 0) return;
      const mandantCode = localStorage.mandantCode || state.app.mandantList[0].code;
      // If mandantCode is not available in mandantList, we fallback to default
      if (state.app.mandantList.map((m) => m.code).indexOf(mandantCode) === -1) {
        state.app.mandantCode = state.app.mandantList[0].code;
        this.commit('saveMandantCode');
        return;
      }
      state.app.mandantCode = mandantCode;
    },
    saveMandantCode(state) {
      localStorage.mandantCode = state.app.mandantCode;
    },
    setMandantCode(state, mandantCode) {
      state.app.mandantCode = mandantCode;
    },
    setMandantList(state, mandantList) {
      state.app.mandantList = mandantList;
    },
  },
  actions: {
    fetchMandantList(context) {
      Vue.superagent
        .get('/mandant/list')
        .then((res) => {
          context.commit('setMandantList', res.body.mandantList);
          context.commit('loadMandantCode');
        })
        .catch(() => {
          // todo: handle fetchMandantList error
        });
    },
  },
  modules: {
  },
});
