import Vue from 'vue';

Vue.mixin({
  methods: {
    isAdmin() {
      if (!Object.prototype.hasOwnProperty.call(this.$store.state, 'account')) return false;
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.account, 'admin')) return false;
      return this.$store.state.account.admin === '1';
    },
    isLogged() {
      if (!Object.prototype.hasOwnProperty.call(this.$store.state, 'account')) return false;
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.account, 'login')) return false;
      return this.$store.state.account.login.length > 0;
    },
  },
});
